import {debounceTime, map, shareReplay, switchMap} from 'rxjs/operators';
import {IteratorQuestionType, NormalQuestionType} from '../enum/question_type';

import {combineLatest, Observable, of} from 'rxjs';
import {findChildrenAndSiblingsRecursiveByPredicate} from '../../support/generic_tree';
import {buildQuestionAnswerTrees} from '../../support/question_answer_tree';
import {sortByCreatedAt, sortByRank} from '../../support/sort_answer';
import {isEmpty} from '../../support/util';
import {getAttachmentDisplayName} from '../appraise/ui/appraise_secondary/attachments_widget/internal/get_attachment_display_name';
import {Answer} from '../models/answer';
import {Question} from '../models/question';
import {QuestionSet} from '../models/question_set';
import {AnswerController} from './answering/answer_controller';
import {AnswerInteractor} from './answering/answer_interactor';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {isCompact} from '../../support/check_mobile';
import {AppraiseSecondaryConfigStackInteractor} from './appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../models/appraise_secondary_config';
import {GlobalProvider} from '../../business/global_provider';
import {hasBrowserPDFViewer} from '../appraise/ui/appraise_secondary/attachments_widget/components/pdf_viewer';

export interface AttachmentPair {
    question: Question;
    answer: Answer;
}

export interface PhotoPair {
    iteratorPhotoGroup: Answer;
    photoAnswer: Answer;
}

export interface AttachmentPairWithDisplayName extends AttachmentPair {
    displayName: string | null;
}

export interface AttachmentModalInfo {
    displayName: string | null;
    url: string | null;
}

export interface AttachmentQuestionsInteractor {
    attachments(): Observable<AttachmentPairWithDisplayName[]>;
    photoPairsStream(): Observable<PhotoPair[]>;
    markVisited(answerUuid: string): void;

    showAttachmentModal(pair: AttachmentModalInfo | null): () => void;
    isAttachmentModalAvailable(): boolean;
}

export class DefaultAttachmentQuestionsInteractor implements AttachmentQuestionsInteractor {
    constructor(
        private questionSet: QuestionSet,
        private globalProvider: GlobalProvider,
        private answerController: AnswerController,
        private answerInteractor: AnswerInteractor,
        private questionEffectInteractor: QuestionEffectInteractor,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor
    ) {}

    public attachments(): Observable<AttachmentPairWithDisplayName[]> {
        const fileGroups = this.questionSet.findQuestionsByType(NormalQuestionType.FILES_GROUP);
        const fileGroupParents = fileGroups.map((question) => {
            if (!question.parentUuid) {
                return question;
            }
            return this.questionSet.findQuestionByUuid(question.parentUuid) ?? question;
        });
        return this.stream(fileGroupParents);
    }

    public markVisited(answerUuid: string) {
        this.answerController.markVisited(answerUuid);
        this.answerInteractor.submit();
    }

    private stream(groups: Question[]) {
        return this.answerController.answersStream().pipe(
            debounceTime(2000),
            map((answers) => this.answerController.filterDeleted(answers)),
            map((answers) => {
                const hiddenCache = {};
                return answers.filter(
                    (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid, hiddenCache)
                );
            }),
            map((answers) => {
                const pairs: AttachmentPairWithDisplayName[] = [];
                const groupTrees = groups
                    .filter((g): g is Question => g !== undefined)
                    .map((g) => buildQuestionAnswerTrees(this.questionSet, answers, g));
                for (const trees of groupTrees) {
                    for (const tree of trees) {
                        const attachmentQuestions = findChildrenAndSiblingsRecursiveByPredicate(
                            tree,
                            (item) => item.question.type === NormalQuestionType.ATTACHMENT
                        );
                        for (const attachmentQuestion of attachmentQuestions) {
                            if (attachmentQuestion.treeNode.item.answer) {
                                let displayName: string | null = attachmentQuestion.treeNode.item.question.contents;
                                if (attachmentQuestion.treeNode.item.question.type === NormalQuestionType.ATTACHMENT) {
                                    const siblingPairs = attachmentQuestion.treeSiblings
                                        .map((ts) => ts.item)
                                        .filter((sibling): sibling is AttachmentPair => sibling.answer !== null);

                                    displayName = getAttachmentDisplayName(
                                        attachmentQuestion.treeNode.item as AttachmentPair,
                                        siblingPairs
                                    );
                                }

                                if (!isEmpty(displayName) && !isEmpty(attachmentQuestion.treeNode.item.answer.file)) {
                                    pairs.push({
                                        question: attachmentQuestion.treeNode.item.question,
                                        answer: attachmentQuestion.treeNode.item.answer,
                                        displayName,
                                    });
                                }
                            }
                        }
                    }
                }
                return pairs;
            }),
            shareReplay(1)
        );
    }

    public photoPairsStream() {
        const photoIterators = this.questionSet.findQuestionsByType(IteratorQuestionType.PHOTO_ITERATOR);
        const smallPhotoIterators = this.questionSet.findQuestionsByType(IteratorQuestionType.PHOTO_ITERATOR_SMALL);

        return this.answerController
            .answersForQuestionUuidsStream(
                photoIterators
                    .map((photoIterator) => photoIterator.uuid)
                    .concat(smallPhotoIterators.map((smallPhotoIterator) => smallPhotoIterator.uuid))
            )
            .pipe(
                map((iteratorPhotoGroups: Answer[]) => iteratorPhotoGroups.sort(sortByCreatedAt).sort(sortByRank)),
                switchMap((iteratorPhotoGroups) => {
                    if (iteratorPhotoGroups.length === 0) {
                        return of([]);
                    }

                    return combineLatest(
                        iteratorPhotoGroups.map((iteratorPhotoGroup) => {
                            const photoQuestion = this.questionSet
                                .findChildQuestionsByParentUuid(iteratorPhotoGroup.questionUuid)
                                .find(
                                    (q) =>
                                        q.type === NormalQuestionType.PHOTO || q.type === NormalQuestionType.PHOTO_SMALL
                                );
                            if (
                                photoQuestion &&
                                (photoQuestion.type === NormalQuestionType.PHOTO || !iteratorPhotoGroup.isDeleted)
                            ) {
                                return this.answerController
                                    .answerByIdentifiersStream(photoQuestion.uuid, iteratorPhotoGroup.uuid, null)
                                    .pipe(
                                        map((photoAnswer) => {
                                            return {
                                                iteratorPhotoGroup,
                                                photoAnswer,
                                            };
                                        })
                                    );
                            } else {
                                return of(null);
                            }
                        })
                    );
                }),
                map((photoAnswers) =>
                    photoAnswers.filter((photoAnswer): photoAnswer is PhotoPair => photoAnswer !== null)
                )
            );
    }

    public showAttachmentModal(pair: AttachmentModalInfo | null) {
        const id = `attachment-${pair?.url}`;

        this.appraiseSecondaryConfigStackInteractor.remove(
            (widget) => widget.type === AppraiseSecondaryType.ATTACHMENT_PREVIEW_WIDGET
        );
        this.appraiseSecondaryConfigStackInteractor.upsert({
            id,
            type: AppraiseSecondaryType.ATTACHMENT_PREVIEW_WIDGET,
            displayName: pair?.displayName ?? null,
            url: pair?.url ?? null,
            onClose: () => null,
        });

        return () => {
            this.appraiseSecondaryConfigStackInteractor.remove(id);
        };
    }

    public isAttachmentModalAvailable(): boolean {
        return (
            !isCompact() &&
            this.globalProvider.global.isAttachmentPreviewWidgetEnabled === true &&
            hasBrowserPDFViewer()
        );
    }
}
