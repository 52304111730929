import {AdaptedDefaultValuesMap} from '../../models/adapted_default_values_map';
import {AdaptedValueBuilder} from './builders/adapted_value_builder';
import {AnswerController} from '../answering/answer_controller';
import {AreaConclusion} from '../../models/area_conclusion';
import {EnergyConclusion} from '../../models/energy_conclusion';
import {NormalQuestionType} from '../../enum/question_type';
import {ObjectAppraiserExplanationAdaptedValueBuilder} from './builders/object_appraiser_explanation_adapted_value_builder';
import {ObjectBuildingsAppraiserExplanationAdaptedValueBuilder} from './builders/object_buildings_appraiser_explanation_adapted_value_builder';
import {Question} from '../../models/question';
import {QuestionSet} from '../../models/question_set';
import {TechnicalReference} from '../../enum/technical_reference';
import {getNewestAnswer} from '../../../support/get_newest_answer';
import {sortByUpdatedAt} from '../../../support/sort_answer';
import {Answer} from '../../models/answer';

export interface AdaptedValueProvider {
    get(
        technicalReferences: TechnicalReference[],
        areaConclusion: AreaConclusion | null,
        energyConclusion: EnergyConclusion | null
    ): AdaptedDefaultValuesMap;
}

export class DefaultAdaptedValueProvider implements AdaptedValueProvider {
    private CHECKED_VALUE = '1';
    private UNCHECKED_VALUE = '0';

    private technicalReferencesWithMultiple = [TechnicalReference.ADAPTED_VALUE_BIJGEBOUWEN];

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private adaptedValueBuilder: AdaptedValueBuilder,
        private objectAppraiserExplanationAdaptedValueBuilder: ObjectAppraiserExplanationAdaptedValueBuilder,
        private objectBuildingsAppraiserExplanationAdaptedValueBuilder: ObjectBuildingsAppraiserExplanationAdaptedValueBuilder
    ) {}

    public get(
        technicalReferences: TechnicalReference[],
        areaConclusion: AreaConclusion | null,
        energyConclusion: EnergyConclusion | null
    ): AdaptedDefaultValuesMap {
        const adaptedDefaultValuesMap: AdaptedDefaultValuesMap = {};
        for (const technicalReference of technicalReferences) {
            if (this.technicalReferencesWithMultiple.includes(technicalReference)) {
                this.addMultipleAdaptedValues(adaptedDefaultValuesMap, technicalReference);
            } else {
                const questions = this.questionSet.findQuestionsByTechnicalReference(technicalReference);
                for (const question of questions) {
                    this.addSingleAdaptedValue(
                        adaptedDefaultValuesMap,
                        question,
                        technicalReference,
                        areaConclusion,
                        energyConclusion
                    );
                }
            }
        }

        return adaptedDefaultValuesMap;
    }

    private addMultipleAdaptedValues(
        adaptedDefaultValuesMap: AdaptedDefaultValuesMap,
        technicalReference: TechnicalReference
    ) {
        switch (technicalReference) {
            case TechnicalReference.ADAPTED_VALUE_BIJGEBOUWEN: {
                const targetBijgebouwAndersQuestions = this.questionSet.findQuestionsByTechnicalReference(
                    TechnicalReference.ADAPTED_VALUE_BIJGEBOUWEN_ANDERS
                );
                const targetBijgebouwQuestions = this.questionSet.findQuestionsByTechnicalReference(
                    TechnicalReference.ADAPTED_VALUE_BIJGEBOUWEN
                );
                const booleanMap = this.getAdaptedDefaultValuesMapForBooleans(
                    [
                        TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW,
                        TechnicalReference.OBJECT_OMSCHRIJVING_AANBOUW,
                        TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW_EXCLUDED,
                    ],
                    targetBijgebouwQuestions
                );
                booleanMap.forEach((value, uuid) => {
                    if (value === this.CHECKED_VALUE) {
                        adaptedDefaultValuesMap[uuid] = value;
                    }
                });
                const sourceQuestionsMap = new Map<TechnicalReference, Question[]>();
                const sourceBijgebouwAndersQuestions = this.questionSet.findQuestionsByTechnicalReference(
                    TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW_ANDERS
                );
                sourceQuestionsMap.set(
                    TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW_ANDERS,
                    sourceBijgebouwAndersQuestions
                );
                const sourceAanbouwAndersQuestions = this.questionSet.findQuestionsByTechnicalReference(
                    TechnicalReference.OBJECT_OMSCHRIJVING_AANBOUW_ANDERS
                );
                sourceQuestionsMap.set(
                    TechnicalReference.OBJECT_OMSCHRIJVING_AANBOUW_ANDERS,
                    sourceAanbouwAndersQuestions
                );

                const othersMap = this.getAdaptedDefaultValuesMapForOtherOptions(
                    sourceQuestionsMap,
                    targetBijgebouwAndersQuestions
                );
                othersMap.forEach((value, uuid) => {
                    if (value === this.CHECKED_VALUE) {
                        adaptedDefaultValuesMap[uuid] = value;
                    } else if (value !== '') {
                        adaptedDefaultValuesMap[uuid] = value;
                    }
                });

                const multipleChoiceMap = this.getAdaptedDefaultValuesMapForMultipleChoice(
                    [TechnicalReference.OBJECT_OMSCHRIJING_PARKEERPLAATS, TechnicalReference.FLOOR_TYPE],
                    targetBijgebouwQuestions
                );

                multipleChoiceMap.forEach((value, uuid) => {
                    if (value === this.CHECKED_VALUE) {
                        adaptedDefaultValuesMap[uuid] = value;
                    }
                });
                break;
            }
            default:
                break;
        }
    }

    private addSingleAdaptedValue(
        adaptedDefaultValuesMap: AdaptedDefaultValuesMap,
        question: Question,
        technicalReference: TechnicalReference,
        areaConclusion: AreaConclusion | null,
        energyConclusion: EnergyConclusion | null
    ) {
        const val = this.getForTechnicalReference(technicalReference, areaConclusion, energyConclusion);
        if (val) {
            adaptedDefaultValuesMap[question.uuid] = val;
        }
    }

    private getForTechnicalReference(
        technicalReference: TechnicalReference,
        areaConclusion: AreaConclusion | null,
        energyConclusion: EnergyConclusion | null
    ): string {
        switch (technicalReference) {
            case TechnicalReference.ADAPTED_VALUE_GETAXEERDE_OBJECT_TOELICHTING_TAXATEUR:
                return this.objectAppraiserExplanationAdaptedValueBuilder.buildSentence(technicalReference);
            case TechnicalReference.ADAPTED_VALUE_GETAXEERDE_OBJECT_AANBOUW_TOELICHTING_TAXATEUR:
                return this.objectBuildingsAppraiserExplanationAdaptedValueBuilder.buildSentence();
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_BUILD_YEAR:
                return this.getAnswerForTechnicalReference(TechnicalReference.OBJECT_BUILD_YEAR) ?? '';
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_BRUTO_INHOUD:
                return this.getAnswerForTechnicalReference(TechnicalReference.OBJECT_BUILD_YEAR) ?? '';
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN:
                return areaConclusion?.gebruiksoppervlakteWonen?.toString(10) || '';
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE:
                return areaConclusion?.gebouwgebondenBuitenruimte?.toString(10) || '';
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE:
                return areaConclusion?.overigeInpandigeRuimte?.toString(10) || '';
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE:
                return areaConclusion?.externeBergruimte?.toString(10) || '';
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE:
                return areaConclusion?.perceelOppervlakte?.toString(10) || '';
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_ENERGY_LABEL:
                return energyConclusion?.label || '';
            default:
                return this.adaptedValueBuilder.buildSentence(technicalReference);
        }
    }

    private getAnswerForTechnicalReference(technicalReference: TechnicalReference): string | null {
        const sourceQuestions = this.questionSet.findQuestionsByTechnicalReference(technicalReference);
        if (sourceQuestions.length > 0) {
            for (const sourceQuestion of sourceQuestions) {
                const answer = this.answerController
                    .answersForQuestionUuid(sourceQuestion.uuid)
                    .sort(sortByUpdatedAt)[0];
                if (answer) {
                    return answer.contents;
                }
            }
        }
        return null;
    }

    private getAdaptedDefaultValuesMapForBooleans(
        sourceTechnicalReferences: TechnicalReference[],
        targetBijgebouwenQuestions: Question[]
    ): Map<string, string> {
        const map = new Map<string, string>();
        for (const sourceTechnicalReference of sourceTechnicalReferences) {
            const sourceQuestions = this.questionSet
                .findQuestionsByTechnicalReference(sourceTechnicalReference)
                .filter((q) => q.type === NormalQuestionType.BOOLEAN);
            const targetQuestions = targetBijgebouwenQuestions.filter((q) => q.type === NormalQuestionType.BOOLEAN);

            for (const targetQuestion of targetQuestions) {
                const match = sourceQuestions.find((q) => {
                    const sourceContentsMapped = this.mapContents(q.contents.toLowerCase());
                    const targetContentsMapped = this.mapContents(targetQuestion.contents.toLowerCase());
                    let targetReportValueMapped = '';
                    let sourceReportValueMapped = '';
                    if (targetQuestion.reportValue && q.reportValue) {
                        targetReportValueMapped = this.mapContents(targetQuestion.reportValue.toLowerCase());
                        sourceReportValueMapped = this.mapContents(q.reportValue.toLowerCase());
                    }

                    return (
                        q.contents.toLowerCase() === targetQuestion.contents.toLowerCase() ||
                        (q.reportValue &&
                            targetQuestion.reportValue &&
                            (q.reportValue.toLowerCase() === targetQuestion.reportValue.toLowerCase() ||
                                sourceReportValueMapped === targetReportValueMapped)) ||
                        sourceContentsMapped === targetContentsMapped
                    );
                });

                let shouldCheck = false;
                if (match) {
                    const answers = this.answerController.answersForQuestionUuid(match.uuid);
                    const nonDeletedAnswers = this.answerController.filterDeleted(answers);
                    shouldCheck = nonDeletedAnswers.filter((a: Answer) => a.contents === '1').length > 0;
                }

                if (!map.has(targetQuestion.uuid)) {
                    map.set(targetQuestion.uuid, shouldCheck ? this.CHECKED_VALUE : this.UNCHECKED_VALUE);
                } else if (map.get(targetQuestion.uuid) === this.UNCHECKED_VALUE && shouldCheck) {
                    map.set(targetQuestion.uuid, this.CHECKED_VALUE);
                }
            }
        }
        return map;
    }

    private mapContents(contents: string): string {
        switch (contents.toLocaleLowerCase()) {
            case 'berging / schuur (aangeb.)':
            case 'berging (aangeb.)':
                return 'Berging (aangeb.)';
            case 'dakkapel verh. nok':
                return 'Dakkapel verh. nok';
            case 'dubbele garage':
                return 'Garage (dubbel)';
            case 'garage':
                return 'Garage (enkel)';
            case 'dakkapel':
                return 'Dakkapel';
            case 'carport':
                return 'Carport';
            case 'garage met kap':
            case 'garage (enkel met kap)':
                return 'Garage met kap';
            default:
                return contents;
        }
    }

    private getAdaptedDefaultValuesMapForMultipleChoice(
        sourceTechnicalReferences: TechnicalReference[],
        targetBijgebouwenQuestions: Question[]
    ): Map<string, string> {
        const map = new Map<string, string>();
        for (const sourceTechnicalReference of sourceTechnicalReferences) {
            const sourceQuestions = this.questionSet
                .findQuestionsByTechnicalReference(sourceTechnicalReference)
                .filter(
                    (q) =>
                        q.type === NormalQuestionType.MC_SELECT_OPTIONAL ||
                        q.type === NormalQuestionType.MC_SELECT ||
                        q.type === NormalQuestionType.MC_SELECT_DYNAMIC
                );
            const targetQuestions = targetBijgebouwenQuestions.filter((q) => q.type === NormalQuestionType.BOOLEAN);

            for (const targetQuestion of targetQuestions) {
                const parkeerplaatsMatch = sourceQuestions.find((q) => {
                    return (
                        q.technicalReference === TechnicalReference.OBJECT_OMSCHRIJING_PARKEERPLAATS &&
                        targetQuestion.contents === 'Parkeerplaats'
                    );
                });
                const floorTypeMatch = sourceQuestions.find((q) => {
                    if (
                        q.technicalReference === TechnicalReference.FLOOR_TYPE &&
                        targetQuestion.contents === 'Kelder'
                    ) {
                        return true;
                    }
                });

                let shouldCheck = false;
                if (parkeerplaatsMatch) {
                    const answers = this.answerController.answersForQuestionUuid(parkeerplaatsMatch.uuid);
                    const nonDeletedAnswers = this.answerController.filterDeleted(answers);
                    const newestAnswer = getNewestAnswer(nonDeletedAnswers);
                    if (newestAnswer) {
                        shouldCheck = newestAnswer.answerOptionId !== null;
                    }
                } else if (floorTypeMatch) {
                    const answerOptions = floorTypeMatch.answerOptions;
                    const kelderAnswerOption = answerOptions.find((ao) => ao.contents === targetQuestion.contents);
                    const answers = this.answerController.answersForQuestionUuid(floorTypeMatch.uuid);
                    const nonDeletedAnswers = this.answerController.filterDeleted(answers);
                    for (const answer of nonDeletedAnswers) {
                        if (answer.answerOptionId === kelderAnswerOption?.id) {
                            shouldCheck = true;
                        }
                    }
                }

                if (!map.has(targetQuestion.uuid)) {
                    map.set(targetQuestion.uuid, shouldCheck ? this.CHECKED_VALUE : this.UNCHECKED_VALUE);
                } else if (map.get(targetQuestion.uuid) === this.UNCHECKED_VALUE && shouldCheck) {
                    map.set(targetQuestion.uuid, this.CHECKED_VALUE);
                }
            }
        }
        return map;
    }

    private getAdaptedDefaultValuesMapForOtherOptions(
        sourceQuestionsForTechnicalReference: Map<TechnicalReference, Question[]>,
        targetQuestionsForTechnicalReference: Question[]
    ): Map<string, string> {
        const map = new Map<string, string>();
        for (const sourceTechnicalReference of sourceQuestionsForTechnicalReference.keys()) {
            const questions = sourceQuestionsForTechnicalReference.get(sourceTechnicalReference);
            if (questions !== undefined) {
                const sourceToggleQuestions = questions.filter((q) => q.type === NormalQuestionType.BOOLEAN);

                const buildingCostGroupQuestions = this.questionSet.questions.filter(
                    (q) =>
                        q.technicalReference === TechnicalReference.BUILDING_COSTS_GROUP_TYPE &&
                        q.type === NormalQuestionType.OPEN_MAX_CHARACTERS
                );

                let sourceDescriptionQuestions = questions
                    .filter((q) => q.type === NormalQuestionType.BUILDING_COSTS_GROUP)
                    .flatMap((q) => this.questionSet.findChildQuestionsByParentUuid(q.uuid))
                    .filter((q) => q.type === NormalQuestionType.OPEN_MAX_CHARACTERS);

                sourceDescriptionQuestions = sourceDescriptionQuestions.concat(buildingCostGroupQuestions);
                if (sourceToggleQuestions.length !== sourceDescriptionQuestions.length) {
                    return map;
                }

                const sourcePairs = sourceToggleQuestions.map<[Question, Question]>((toggle, i) => [
                    toggle,
                    sourceDescriptionQuestions[i],
                ]);

                const targetToggleOptions = targetQuestionsForTechnicalReference.filter(
                    (q) => q.type === NormalQuestionType.BOOLEAN
                );
                const targetDescriptionOptions = targetQuestionsForTechnicalReference.filter(
                    (q) => q.type === NormalQuestionType.OPEN_MAX_CHARACTERS
                );

                if (targetToggleOptions.length !== targetDescriptionOptions.length) {
                    return map;
                }

                const targetPairs = targetToggleOptions.map<[Question, Question]>((toggle, i) => [
                    toggle,
                    targetDescriptionOptions[i],
                ]);

                for (const [toggle, description] of targetPairs) {
                    const match = sourcePairs.find(
                        (src) =>
                            src[0].contents.toLowerCase() === toggle.contents.toLowerCase() ||
                            (src[0].reportValue &&
                                toggle.reportValue &&
                                src[0].reportValue.toLowerCase() === toggle.reportValue.toLowerCase())
                    );
                    let shouldCheck = false;

                    if (match) {
                        const answers = this.answerController.answersForQuestionUuid(match[0].uuid);
                        const nonDeletedAnswers = this.answerController.filterDeleted(answers);
                        shouldCheck = nonDeletedAnswers.filter((a: Answer) => a.contents === '1').length > 0;
                    }

                    if (!map.has(toggle.uuid)) {
                        map.set(toggle.uuid, shouldCheck ? this.CHECKED_VALUE : this.UNCHECKED_VALUE);
                    } else if (map.get(toggle.uuid) === this.UNCHECKED_VALUE && shouldCheck) {
                        map.set(toggle.uuid, this.CHECKED_VALUE);
                    }

                    if (match) {
                        const srcDescription = getNewestAnswer(
                            this.answerController.filterDeleted(
                                this.answerController.answersForQuestionUuid(match[1].uuid)
                            )
                        );

                        if (srcDescription?.contents) {
                            if (!map.has(srcDescription.uuid)) {
                                map.set(description.uuid, srcDescription.contents);
                            } else if (map.get(srcDescription.uuid) === '' && srcDescription.contents) {
                                map.set(description.uuid, srcDescription.contents);
                            }
                        }
                    }
                }
            }
        }
        return map;
    }
}
