import {observer} from 'mobx-react';
import * as React from 'react';
import {classNames} from '../../../../../support/classnames';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {TextAIChatWidgetPresenter} from './textai_chat_widget_presenter';
import {PdfViewer} from '../attachments_widget/components/pdf_viewer';
import {TextAIRole} from '../../../../network/text_ai_api';
import {Modal} from '../../content/questions/components/modal';

export interface TextAIChatWidgetProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    answerUuid: string;
    onClose: () => void;
}

export const TextAIChatWidget: React.FC<TextAIChatWidgetProps> = observer(
    ({onClose, answerUuid, appraisal, questionSet}: TextAIChatWidgetProps) => {
        const presenter = usePresenter(
            (component) =>
                new TextAIChatWidgetPresenter(
                    answerUuid,
                    component.business.answerController(appraisal, questionSet),
                    component.business.textAIInteractor(appraisal, questionSet)
                )
        );

        const [page, setPage] = React.useState<number>();
        const [explanationVisible, setExplanationVisible] = React.useState(false);

        function renderMessage(content: string) {
            // Only render page buttons if a file is present
            if (!presenter.file) return content;

            const matches = content.match(/\[(?:P\d+,? ?)+\]/g);
            if (matches) {
                return (
                    <>
                        {content.split(/\[(?:P\d+,? ?)+\]/).map((text, index) => (
                            <React.Fragment key={index}>
                                {text}
                                {matches[index] &&
                                    matches[index].split(',').map((match) => (
                                        <button
                                            key={match}
                                            className="btn btn-xs textai-chat-page-button d-inline-flex mr-1"
                                            onClick={() => setPage(parseInt(match.replace(/\D/g, ''), 10))}
                                        >
                                            {match.replace(/\D/g, '')}
                                        </button>
                                    ))}
                            </React.Fragment>
                        ))}
                    </>
                );
            }

            return content;
        }

        return (
            <div className="widget-overlay">
                <div className="widget-overlay-container d-flex flex-column">
                    <div className="widget-overlay-title">
                        <span className="widget-overlay-title-icon ion-md-color-wand">&nbsp;</span>
                        <div className="d-flex">
                            <h2 className="w-auto">AI-chat</h2>
                            <a className="ml-2 ion-md-help-circle-outline" onClick={() => setExplanationVisible(true)}>
                                Meer uitleg
                            </a>
                        </div>
                        <button className="widget-overlay-close" onClick={() => onClose()}>
                            <span className="ion-md-close">&nbsp;</span>
                        </button>
                    </div>
                    {presenter.file ? (
                        <div className="textai-chat-pdf">
                            <PdfViewer src={presenter.file.url} showToolbar page={page} skipAutoDownload />
                        </div>
                    ) : null}
                    <div className="textai-chat">
                        {/* Renders in reverse due to flex-direction: reverse-column. This is to make sure container is scrolled to bottom by default */}
                        {presenter.response !== null && (
                            <div className="chat-message ai streaming">
                                {renderMessage(presenter.response)}
                                {presenter.loading && <span className="ai-cursor">|</span>}
                            </div>
                        )}
                        {Array.from(presenter.messages)
                            .reverse()
                            .map((message, index) => (
                                <div className={classNames('chat-message', message.role)} key={index}>
                                    {renderMessage(message.content)}
                                    {message.role === TextAIRole.ASSITANT && (
                                        <button
                                            className="btn btn-primary btn-xs textai-chat-copy-answer-button ion-md-color-wand"
                                            title="Neem dit bericht over als het antwoord op de vraag."
                                            onClick={() => presenter.setAnswer(message.content)}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                    <div className="input-group">
                        <textarea
                            className="form-control textai-chat-input"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    presenter.chat(presenter.promptInput);
                                }
                            }}
                            value={presenter.promptInput}
                            rows={2}
                            onChange={(e) => presenter.setPromptInput(e.target.value)}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary btn-append"
                                title="Versturen"
                                onClick={() => presenter.chat(presenter.promptInput)}
                                disabled={presenter.loading}
                            >
                                <span className="ion-md-send" style={{fontSize: 20}}></span>
                            </button>
                        </div>
                    </div>
                </div>
                <Modal visible={explanationVisible} onHide={() => setExplanationVisible(false)}>
                    <div className="modal-header">
                        <h2>AI-chat</h2>
                    </div>
                    <div className="modal-body pt-0">
                        <div className="d-flex justify-content-center">
                            <span className="ion-md-color-wand" style={{fontSize: 100}}></span>
                        </div>
                        <p>
                            Onze chatwidget stelt je in staat om direct te communiceren met onze AI-assistent. Typ je
                            vraag in het tekstvak en ontvang direct een antwoord. Terwijl je vragen stelt, begrijpt onze
                            AI automatisch de specifieke details van je taxatie aan de hand van de informatie die bekend
                            is in taXapi.
                        </p>
                        {presenter.file && (
                            <p>
                                De AI-assistent heeft ook toegang tot de getoonde bijlage. Stel hier vragen over en
                                ontvang een antwoord met ook een referentie naar de pagina waar het antwoord te vinden
                                is. Gebruik dit bijvoorbeeld om automatisch een samenvatting te ontvangen uit de
                                bijlage, of om snel een pagina te vinden waar iets specifieks genoemd staat.
                            </p>
                        )}
                        <p>
                            Wanneer je met je muis op een bericht staat, is het via de knop{' '}
                            <button className="btn btn-xs ion-md-color-wand d-inline" style={{pointerEvents: 'none'}} />{' '}
                            mogelijk om het AI-antwoord automatisch over te nemen in de opname.
                        </p>

                        <div className="alert-inline alert-hint ion-md-information-circle">
                            <p>
                                <strong>Let op</strong>: de AI-assistent heeft een uitgebreide algemene kennis, maar is
                                geen taxateur. Controleer de antwoorden altijd aan de hand van je eigen expertise.
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-primary btn-full ion-md-close"
                            type="button"
                            onClick={() => setExplanationVisible(false)}
                        >
                            Sluiten
                        </button>
                    </div>
                </Modal>
            </div>
        );
    }
);
