import {makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../models/appraisal';
import {ConceptReportProvider} from '../../../../business/concept_report_provider';
import {Presenter} from '../../../../../support/presenter/presenter';

export class ReportRendererPresenter implements Presenter {
    @observable public initialConceptReportUrl: string | null = null;
    @observable public hasError = false;

    constructor(private appraisal: Appraisal, private conceptReportProvider: ConceptReportProvider) {
        makeObservable(this);
    }

    public mount() {
        this.conceptReportProvider
            .getConceptReportUrl(this.appraisal.id)
            .then((url) => {
                runInAction(() => {
                    this.initialConceptReportUrl = url;
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.hasError = true;
                });
            });
    }

    public unmount() {
        //
    }
}
