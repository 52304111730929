import * as React from 'react';
import {Loader} from '../../../components/loader';

interface OwnProps {
    src: string | null;
    showToolbar?: boolean;
    page?: number;
    loadingMessage?: string;
    skipAutoDownload?: boolean;
}

export function hasBrowserPDFViewer() {
    if (navigator.pdfViewerEnabled) {
        return true;
    }

    const hasNavigatorPlugin = (name: string) => {
        for (const key in navigator.plugins) {
            if (navigator.plugins[key].name == name) {
                return true;
            }
        }

        return false;
    };

    return (
        hasNavigatorPlugin('Adobe Acrobat') ||
        hasNavigatorPlugin('Chrome PDF Viewer') ||
        hasNavigatorPlugin('WebKit built-in PDF')
    );
}

export const PdfViewer: React.FC<OwnProps> = ({src, showToolbar = false, page, loadingMessage, skipAutoDownload}) => {
    const query = React.useMemo(() => {
        const params = new URLSearchParams();

        if (!showToolbar) {
            params.set('toolbar', '0');
            params.set('navpanes', '0');
            params.set('scrollbar', '0');
            params.set('statusbar', '0');
        }

        if (page !== undefined) {
            params.set('page', page.toString());
        }

        params.set('view', 'FitH');

        return params.toString();
    }, [showToolbar, page]);

    const [blobUrl, setBlobUrl] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (!src) {
            setBlobUrl(null);
            return;
        }

        if (hasBrowserPDFViewer()) {
            // Make sure the iframe doesnt download the pdf by wrapping it in a blob url
            fetch(src)
                .then(async (res) => {
                    const buffer = await res.arrayBuffer();

                    setBlobUrl(URL.createObjectURL(new File([buffer], 'Testnaam', {type: 'application/pdf'})));
                })
                .catch(() => {
                    setBlobUrl(null);
                });
            return;
        }

        if (!skipAutoDownload) {
            const link = document.createElement('a');
            link.href = src;
            link.click();
        }
    }, [src]);

    if (!src) {
        return (
            <div className="text-center">
                <Loader icon="c-icon c-icon-concept-report" addSpace />
                <h3>{loadingMessage ?? 'PDF aan het laden...'}</h3>
            </div>
        );
    }

    if (!hasBrowserPDFViewer()) {
        if (skipAutoDownload) {
            return (
                <div className="empty-message appraise">
                    <h3>PDF beschikbaar</h3>
                    <span className="spacer-gradient">&nbsp;</span>
                    <span>
                        <a href={src} target="_blank" rel="noreferrer">
                            Klik hier
                        </a>{' '}
                        om het bestand te openen.
                    </span>
                </div>
            );
        } else {
            return (
                <div className="empty-message appraise">
                    <h3>PDF aan het downloaden...</h3>
                    <span className="spacer-gradient">&nbsp;</span>
                    <span>
                        Geen bestand?{' '}
                        <a href={src} target="_blank" rel="noreferrer">
                            Klik hier
                        </a>
                    </span>
                </div>
            );
        }
    }

    if (!blobUrl) {
        return (
            <div className="text-center">
                <Loader icon="c-icon c-icon-concept-report" addSpace />
                <h3>{loadingMessage ?? 'PDF aan het laden...'}</h3>
            </div>
        );
    }

    return <iframe style={{width: '100%', height: '100%'}} key={query} src={blobUrl + '#' + query} />;
};
