import * as React from 'react';

import {TaskHelper} from '../../../../business/task_helper';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {Appraisal} from '../../../../models/appraisal';
import {Component} from '../../../component';
import {ReportRendererPresenter} from './report_renderer_presenter';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {PdfViewer} from '../../appraise_secondary/attachments_widget/components/pdf_viewer';

interface OwnProps {
    appraisal: Appraisal;
    renderingContext: RenderingContextType;
}

export const ReportRenderer: React.FC<OwnProps> = observer(function ReportRenderer(props) {
    const presenter = usePresenter(
        (container: Component) =>
            new ReportRendererPresenter(
                props.appraisal,
                container.business.conceptReportProvider(
                    new TaskHelper(container.network.ajaxDriver),
                    container.network.appraisalApi
                )
            )
    );

    return (
        <>
            <div className="concept-report-renderer">
                <div className="row">
                    <div className="col-12">
                        {presenter.hasError ? (
                            <div className="col-12 text-center">
                                <h2>Fout</h2>
                                <h3>Er is wat mis gegaan met het genereren...</h3>
                            </div>
                        ) : (
                            <PdfViewer
                                src={presenter.initialConceptReportUrl}
                                showToolbar
                                loadingMessage="Concept rapport aan het genereren"
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
});
